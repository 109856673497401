export function secondsToHumanTime(seconds, skipHours = false) {
	const h = Math.floor(seconds / 3600)
		.toString()
		.padStart(2, '0');
	const m = Math.floor((seconds % 3600) / 60)
		.toString()
		.padStart(2, '0');
	const s = Math.floor(seconds % 60)
		.toString()
		.padStart(2, '0');

	let formattedTime = `${m}:${s}`;
	if (!skipHours) {
		formattedTime = `${h}${formattedTime}`;
	}
	return formattedTime;
}
