<template>
	<PageLayout :title="$t('confirm-phone.title')" :subtitle="$t('confirm-phone.confirm-subtitle')" hide-back-button>
		<div class="small-width">
			<MaterialPhoneInput
				:default-country="getUser.country"
				:mobilenumber="form.phone.value"
				:error="form.phone.error"
				:label="$t('profile-info.phone.title')"
				placeholder="+132 345 567"
				@set-mobile-number="setMobileNumber"
			/>
			<CButton primary :loading="loading" :disabled="throttleCountdown !== null" @click="handleSendCode">
				{{ throttleCountdown !== null ? throttleCountdownFormatted : $t('confirm-phone.send-message') }}
			</CButton>

			<CLabel v-if="!loading && (throttled || throttleCountdown !== null)" faded small>
				{{ $t('confirm-phone.throttled') }}
			</CLabel>

			<FormError v-if="networkError" error="network-error" />
		</div>
	</PageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import PageLayout from '@/components/page-layout/PageLayout';
import MaterialPhoneInput from '@/shared/material-phone-input/MaterialPhoneInput';
import CButton from '@/shared/cbutton/CButton';
import apiClient from '@/api';
import { secondsToHumanTime } from '@/utils/generic';
import FormError from '@/shared/forms/FormError';
import CLabel from '@/shared/clabel/CLabel';

const THROTTLE_TIME_SECONDS = 90;

export default {
	name: 'SendVerificationCode',
	components: {
		CButton,
		CLabel,
		MaterialPhoneInput,
		PageLayout,
		FormError,
	},
	mounted() {
		this.updateStateFromVuex(this.getUser);
	},
	computed: {
		...mapGetters(['getUser', 'getSmsVerificationLastSent']),
		throttleCountdownFormatted() {
			if (this.throttleCountdown) {
				return secondsToHumanTime(this.throttleCountdown, true);
			}
			return '';
		},
	},
	watch: {
		getSmsVerificationLastSent: {
			handler(lastSentTime) {
				if (lastSentTime) {
					const currentTime = new Date();
					if ((currentTime - lastSentTime) / 1000 < THROTTLE_TIME_SECONDS) {
						this.startThrottleCountdown();
					}
				}
			},
			immediate: true,
		},
	},
	data() {
		return {
			form: {
				phone: {
					value: '',
					error: null,
					isValid: false,
				},
			},
			loading: false,
			networkError: false,
			throttled: false,
			throttleCountdown: null,
		};
	},
	methods: {
		...mapActions(['updateUser', 'setSmsVerificationLastSent']),
		startThrottleCountdown() {
			this.throttleCountdown =
				THROTTLE_TIME_SECONDS - Math.floor((new Date() - this.getSmsVerificationLastSent) / 1000);
			this.throttleCountdownTimer();
		},
		throttleCountdownTimer() {
			if (this.throttleCountdown > 0) {
				setTimeout(() => {
					this.throttleCountdown -= 1;
					this.throttleCountdownTimer();
				}, 1000);
			} else {
				this.throttleCountdown = null;
			}
		},
		async handleSendCode() {
			try {
				this.loading = true;
				this.throttled = false;
				this.networkError = false;

				if (this.form.phone.value !== this.getUser.mobilenumber || !this.form.phone.isValid) {
					const success = await this.updateUserMobilenumber();
					if (!success) {
						return;
					}
				}

				await apiClient.sendMobileConfirmationToken();
				await this.$router.push('/confirm-phone/verify');
				this.setSmsVerificationLastSent(new Date());
			} catch (e) {
				this.error = e.message;
				console.error(e);
				if (e?.response?.status === 429) {
					this.throttled = true;
				}
			} finally {
				this.loading = false;
			}
		},
		async updateUserMobilenumber() {
			if (!this.form.phone.isValid) {
				this.form.phone.error = this.$t('profile-info.phone.invalid');
			}

			try {
				await this.updateUser({
					mobilenumber: this.form.phone.value,
				});
				return true;
			} catch (e) {
				const { response } = e;
				if (!response || (response.data.error && typeof response.data.error === 'string')) {
					Sentry.captureException(e);
					this.networkError = true;
				} else if (response.data.errors && response.data.errors.mobilenumber) {
					this.form.phone.error = response.data.errors.mobilenumber;
				}

				return false;
			}
		},
		updateStateFromVuex(user) {
			this.form.phone.value = user.mobilenumber;
		},
		setMobileNumber(event) {
			this.form.phone.value = event.number.input;
			this.form.phone.isValid = event.isValid;
		},
	},
};
</script>
